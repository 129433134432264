import Rails from "@rails/ujs"

document.addEventListener("DOMContentLoaded", () => {
  const darkModeToggle = document.getElementById("dark-mode-toggle");

  if (darkModeToggle) {
    darkModeToggle.addEventListener("change", (event) => {
      Rails.ajax({
        url: darkModeToggle.dataset.url,
        type: "POST",
        data: `dark_mode=${event.target.checked}`,
        success: () => {
          document.body.classList.toggle("dark", event.target.checked);
        }
      });
    });
  }
});
